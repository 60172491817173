import React from 'react'
import LayoutMain from '../components/layouts/LayoutMain'

const ErrorPage = () => {
	return (
		<LayoutMain>
			<h1>OOPS! The page you are looking for cant be found.</h1>
		</LayoutMain>
	)
}

export default ErrorPage
